import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BRAND_NAME } from "../util/constants";

const TermsPage = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <section className="section">
      <Row>
        <Col>
          <h1>Terms of Service</h1>

          <p>
            <em>Last Updated: September 7, 2019</em>
          </p>
          <p>
            These Terms govern your access to and use of {`${BRAND_NAME} `} as well as
            all content and Splatboard LLC products and services available at or
            though these websites and supporting TV, mobile, and native
            applications (collectively, the “Services”).
          </p>
          <p>
            These Terms also govern visitors’ access to and use of any websites
            that use our Services, such as location pages hosted on {`${BRAND_NAME} `}
            that are operated by our customers.
          </p>
          <p>
            Our Services are offered subject to your acceptance, without
            modification, of all of the terms and conditions contained herein
            and all other operating rules, policies (including, without
            limitation, Splatboard's Privacy Policy), and procedures that may be
            published from time to time by Splatboard (collectively, the
            “Agreement”). You agree that we may automatically upgrade our
            Services, and the Agreement will apply to any upgrades.
          </p>
          <p>
            Please read the Agreement carefully before accessing or using our
            Services. By accessing or using any part of our Services, you agree
            to become bound by the Agreement. If you do not agree to all the
            terms of the Agreement, then you may not access or use our Services.
          </p>
          <h2>
            Who's Who
          </h2>
          <p>
            Throughout these Terms, “you” applies to both individuals and
            entities that access or use our Services. If you are an individual
            using our Services on behalf of an entity, you represent and warrant
            that you have the authority to bind that entity to the Agreement and
            that by using our Service(s), you are accepting the Agreement on
            behalf of that entity.
          </p>
          <p>
            We refer to Splatboard LLC collectively as “Splatboard” or “we”
            throughout these Terms.
          </p>
          <h2>
            Your Account
          </h2>
          <p>
            Where use of our Services requires an account, you agree to provide
            us with complete and accurate information when you register for an
            account. You will be solely responsible and liable for any activity
            that occurs under your account. You are responsible for keeping your
            account information up-to-date and for keeping your password secure.
          </p>
          <p>
            You are responsible for maintaining the security of your account and
            any Service-related content, and you are fully responsible for all
            activities that occur under your account and any other actions taken
            in connection with our Services. You shall not share or misuse your
            access credentials. You must immediately notify us of any
            unauthorized uses of your account, store, or website, or of any
            other breach of security. We will not be liable for any acts or
            omissions by you, including any damages of any kind incurred as a
            result of such acts or omissions.
          </p>
          <p>
            When you create a {`${BRAND_NAME} `} account, we consider that to be an
            inquiry about our products and services, which means that we may
            contact you to share more details about what we have to offer. Don’t
            worry — if you aren’t interested in learning more, you can opt out
            of the marketing communication, whether it’s an email, phone call,
            or text message.
          </p>
          <h2>
            Responsibility of Visitors and Users
          </h2>
          <p>
            We have not reviewed, and cannot review, all of the content (such
            as, but not limited to, text, photo, video, audio, code, goods
            offered for sale, or other materials) posted to our Services by
            users or anyone else (“Content”) and are not responsible for any use
            or effects of such Content. So, for example:
          </p>
          <ul>
            <li>
              We do not endorse any Content or represent that Content is
              accurate, useful, or non-harmful. Content could be offensive,
              indecent, or objectionable; include technical inaccuracies,
              typographical mistakes, or other errors; or violate or infringe
              the privacy, publicity rights, intellectual property rights (see
              our Copyright Infringement and DMCA Policy section to submit
              copyright complaints), or other proprietary rights of third
              parties.
            </li>
            <li>
              If you post Content or otherwise make (or allow any third party to
              make) Content available on our Services, you are entirely
              responsible for the Content, and any harm resulting from, that
              Content or your conduct.
            </li>
            <li>
              We disclaim any responsibility for any harm resulting from
              anyone’s use, purchase, or downloading of Content. If you access
              or use any Content, you are responsible for taking precautions as
              necessary to protect yourself and your computer systems from
              viruses, worms, Trojan horses, and other harmful or destructive
              content.
            </li>
            <li>
              Any Content or goods offered for sale through any of our Services
              are the seller’s sole responsibility, and you agree that you will
              look solely to the seller for any damages that result from your
              purchase or use of Content.
            </li>
            <li>
              We are not a party to, and will have no responsibility or
              liability for, any communications, transactions, interactions, or
              disputes between you and the provider of any Content.
            </li>
            <li>
              Please note that additional third party terms and conditions may
              apply to the downloading, copying, purchase, or use of Content.
            </li>
          </ul>
          <h2>
            Payment
          </h2>
          <p>
            Some of our Services are offered for a fee (collectively, “Paid
            Services”). By using a Paid Service, you agree to pay the specified
            fees. Depending on the Paid Service, there may be a one-time fee,
            recurring fees, or usage-based fees. For recurring fees, we’ll bill
            or charge you for in regular automatically-renewing intervals (such
            as monthly, annually, or biennially), on a pre-pay basis until you
            cancel, which you can do at any time by contacting our support team.
            For usage-based fees related to specific resources — such as fees
            for Additional Locations in your {`${BRAND_NAME} `} Team — you pay us for
            the peak count of those resources active in your account during the
            billing period.
          </p>
          <p>
            If your payment fails or if Paid Services are otherwise not paid for
            or paid for on time, we may immediately cancel or revoke your access
            to the Paid Services. If you contact your bank or credit card
            company to decline or reverse the charge of fees for Paid Services,
            we may revoke your access to our Services in general.
          </p>
          <p>
            We may change our fees at any time, or start charging fees for
            Services that were previously free. When applicable, we may give you
            advance notice of the fee changes. If you don’t agree with the fee
            changes, you must cancel your Paid Service.
          </p>
          <p>
            We want you to be happy with our services. In the event of a
            disagreement or dispute related to payment, please email our support
            team and we will do our best to make things right.
          </p>
          <p>
            While you may cancel a Paid Service at any time, refunds are issued
            in our sole discretion, unless otherwise required by applicable law.
          </p>
          <h2>
            Copyright Infringement and DMCA Policy
          </h2>
          <p>
            As we ask others to respect our intellectual property rights, we
            respect the intellectual property rights of others. If you believe
            that material located on or associated with an Splatboard product or
            service violates your copyright, please notify us in accordance with
            Splatboard's Digital Millennium Copyright Act (“DMCA”) Policy. We
            will respond to all such notices, including as required or
            appropriate by removing the infringing material or disabling all
            links to the infringing material. We will terminate a visitor’s
            access to and use of the website if, under appropriate
            circumstances, the visitor is determined to be a repeat infringer of
            the copyrights or other intellectual property rights of Splatboard
            or others. In the case of such termination, we will have no
            obligation to provide a refund of any amounts previously paid to us.
          </p>
          <h2>
            General Representation and Warranty
          </h2>
          <p>You represent and warrant that your use of our Services:</p>
          <ul>
            <li>Will be in strict accordance with these Terms;</li>
            <li>
              Will comply with all applicable laws and regulations (including,
              without limitation, all applicable laws regarding online conduct
              and acceptable content, privacy, data protection, and the
              transmission of technical data exported from the United States or
              the country in which you reside);
            </li>
            <li>
              Will not use the Services for any unlawful purposes, to publish
              illegal content, or in furtherance of illegal activities;
            </li>
            <li>
              Will not infringe or misappropriate the intellectual property
              rights of any third party;
            </li>
            <li>
              Will not overburden Splatboard's systems, as determined by us in
              our sole discretion;
            </li>
            <li>Will not disclose sensitive personal information of others;</li>
            <li>Will not be used to send spam or bulk unsolicited messages;</li>
            <li>
              Will not interfere with, disrupt, or attack any service or
              network; and
            </li>
            <li>
              Will not be used to create, distribute, or enable material that is
              – or that facilitates or operates in conjunction with – malware,
              spyware, adware, or other malicious programs or code.
            </li>
          </ul>
          <h2>
            Content Rights
          </h2>
          <p>
            Splatboard does not claim ownership of any content you submit ("User
            Content"), such as written descriptions, photos, and other media. By
            submitting Content to Splatboard through the Services, you grant
            Splatboard a world-wide, royalty-free, and non-exclusive license to
            use, reproduce, modify, adapt, publish, translate, create derivative
            works from, distribute, perform and publicly display your User
            Content, in whole or in part, in any manner or media and for any
            purpose whatsoever at our sole discretion, including, without
            limitation, for publicity, promotional, advertising, trade,
            business, illustration, artistic and other commercial and
            noncommercial purposes.
          </p>
          <p>
            You acknowledge and agree that Splatboard may disclose or use any
            User Content that you Transmit for purposes that include, but are
            not limited to: (a) enforcing these Terms and Conditions; (b)
            complying with any laws, regulations or rules of any federal, state
            or local government or agency; (c) responding to claims that any
            User Content violates the rights of third parties; or (d) protecting
            the rights or property of Company, its customers or the public.
          </p>
          <h2>
            Unsolicited Ideas and Feedback
          </h2>
          <p>
            Separate and apart from User Content, you can submit questions,
            comments, suggestions, ideas, original or creative materials or
            other information about Splatboard or Services (collectively,
            “Feedback”). By submitting such Feedback, you agree it is
            non-confidential and shall become the sole property of Splatboard.
            Splatboard shall own exclusive rights, including, without
            limitation, all intellectual property rights, in and to such
            Feedback and shall be entitled to the unrestricted use and
            dissemination of this Feedback for any purpose, commercial or
            otherwise, without acknowledgment or compensation to you.
          </p>
          <h2>
            Intellectual Property
          </h2>
          <p>
            The Agreement does not transfer from Splatboard to you any
            Splatboard or third party intellectual property, and all right,
            title, and interest in and to such property will remain (as between
            the parties) solely with Splatboard. Splatboard, {`${BRAND_NAME}`}, the
            {`${BRAND_NAME} `} logo, and all other trademarks, service marks, graphics,
            and logos used in connection with our Services, are trademarks or
            registered trademarks of Splatboard or Splatboard's licensors. Other
            trademarks, service marks, graphics, and logos used in connection
            with our Services may be the trademarks of other third parties. Your
            use of our Services grants you no right or license to reproduce or
            otherwise use any Splatboard or third party trademarks.
          </p>
          <h2>
            Third Party Services
          </h2>
          <p>
            In using the Services, you may enable or use services, products,
            software (like themes or plugins), embeds, or applications developed
            by a third party or yourself (“Third Party Services”) with your
            account.
          </p>
          <p>If you use any Third Party Services, you understand that:</p>
          <ul>
            <li>
              Third Party Services are not vetted, endorsed, or controlled by
              Splatboard.
            </li>
            <li>
              Any use of a Third Party Service is at your own risk, and we shall
              not be responsible or liable to anyone for Third Party Services.
            </li>
            <li>
              Your use is solely between you and the respective third party
              (“Third Party”) and will be governed by the Third Party’s terms
              and policies. It is your responsibility to review the Third
              Party’s terms and policies before using a Third Party Service.
            </li>
            <li>
              Some Third Party Services may request or require access to your
              data, for example, via an API integration. If you use the Third
              Party Service or grant access, your data will be handled in
              accordance with the Third Party’s privacy policy and practices. We
              do not have control over how a Third Party Service may use your
              data. You should carefully review Third Party Services’ data
              collection, retention, and use policies and practices before
              enabling Third Party Services.
            </li>
            <li>
              Third Party Services may not work appropriately with your account,
              and we may not be able to provide support for issues caused by any
              Third Party Services.
            </li>
            <li>
              If you have questions or concerns about how a Third Party Service
              operates, or need support, please contact the Third Party
              directly.
            </li>
            <li>
              In rare cases, we may at our discretion, suspend, disable, or
              remove Third Party Services from your account or website.
            </li>
          </ul>
          <h2>
            Changes
          </h2>
          <p>
            We are constantly updating our Services and that means sometimes we
            have to change the legal terms under which our Services are offered.
            These Terms may only be modified by a written amendment signed by an
            authorized executive of Splatboard, or by the posting by Splatboard
            of a revised version. If we make changes that are material, we will
            let you know by posting on one of our blogs, or by sending you an
            email or other communication before the changes take effect. The
            notice will designate a reasonable period of time after which the
            new terms will take effect. If you disagree with our changes, then
            you should stop using our Services within the designated notice
            period, or once the changes become effective. Your continued use of
            our Services will be subject to the new terms. However, any dispute
            that arose before the changes shall be governed by the Terms
            (including the binding individual arbitration clause) that were in
            place when the dispute arose.
          </p>
          <h2>
            Termination
          </h2>
          <p>
            We may terminate your access to all or any part of our Services at
            any time, with or without cause, with or without notice, effective
            immediately. We have the right (though not the obligation) to, in
            our sole discretion, (i) reclaim your username or website’s URL due
            to prolonged inactivity, (ii) refuse or remove any content that, in
            our reasonable opinion, violates any Splatboard policy or is in any
            way harmful or objectionable, (iii) ask you to make some
            adjustments, restrict the resources your team uses, or terminate
            your Services, if we believe your team's storage or bandwidth usage
            is out of hand and burdens our systems, or (iv) terminate or deny
            access to and use of any of our Services to any individual or entity
            for any reason. We will have no obligation to provide a refund of
            any amounts previously paid.
          </p>
          <p>
            If you wish to terminate the Agreement or your {`${BRAND_NAME} `} account,
            you may simply discontinue using our Services, or, if you are using
            a paid service, you may cancel at any time, subject to the Fees,
            Payment, and Renewal section in these Terms.
          </p>
          <p>
            All provisions of the Agreement which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity,
            and limitations of liability.
          </p>
          <h2>
            Disclaimer of Warranties
          </h2>
          <p>
            Our Services are provided “as is.” Splatboard and its suppliers and
            licensors hereby disclaim all warranties of any kind, express or
            implied, including, without limitation, the warranties of
            merchantability, fitness for a particular purpose and
            non-infringement. Neither Splatboard, nor its suppliers and
            licensors, makes any warranty that our Services will be error free
            or that access thereto will be continuous or uninterrupted. You
            understand that you obtain content or services through our Services
            at your own discretion and risk.
          </p>
          <h2>
            Jurisdiction and Applicable Law.
          </h2>
          <p>
            Except to the extent any applicable law provides otherwise, the
            Agreement and any access to or use of our Services will be governed
            by the laws of the state of New York, U.S.A., excluding its conflict
            of law provisions. The proper venue for any disputes arising out of
            or relating to the Agreement and any access to or use of our
            Services will be the state and federal courts located in New York
            County, New York.
          </p>
          <h2>
            Limitation of Liability
          </h2>
          <p>
            In no event will Splatboard, or its suppliers or licensors, be
            liable with respect to any subject matter of the Agreement under any
            contract, negligence, strict liability or other legal or equitable
            theory for: (i) any special, incidental or consequential damages;
            (ii) the cost of procurement for substitute products or services;
            (iii) for interruption of use or loss or corruption of data; or (iv)
            for any amounts that exceed $50 or the fees paid by you to
            Splatboard under the Agreement during the twelve (12) month period
            prior to the cause of action, whichever is greater. Splatboard shall
            have no liability for any failure or delay due to matters beyond
            their reasonable control. The foregoing shall not apply to the
            extent prohibited by applicable law.
          </p>
          <h2>
            Indemnification
          </h2>
          <p>
            You agree to indemnify and hold harmless Splatboard, its
            contractors, and its licensors, and their respective directors,
            officers, employees, and agents from and against any and all losses,
            liabilities, demands, damages, costs, claims, and expenses,
            including attorneys’ fees, arising out of or related to your use of
            our Services, including but not limited to your violation of the
            Agreement, Content that you post, and any commerce activities
            conducted in relation to your use of Services.
          </p>
          <h2>
            US Economic Sanctions
          </h2>
          <p>
            You expressly represent and warrant that your use of our Services
            and/or associated services and products is not contrary to
            applicable U.S. Sanctions. Such use is prohibited, and we reserve
            the right to terminate accounts or access of those in the event of a
            breach of this condition.
          </p>
          <h2>
            Translation
          </h2>
          <p>
            These Terms were originally written in English (US). We may
            translate these terms into other languages. In the event of a
            conflict between a translated version of these Terms and the English
            version, the English version will control.
          </p>
          <h2>
            Miscellaneous
          </h2>
          <p>
            The Agreement constitutes the entire agreement between Splatboard
            and you concerning the subject matter hereof. If any part of the
            Agreement is held invalid or unenforceable, that part will be
            construed to reflect the parties’ original intent, and the remaining
            portions will remain in full force and effect. A waiver by either
            party of any term or condition of the Agreement or any breach
            thereof, in any one instance, will not waive such term or condition
            or any subsequent breach thereof.
          </p>
          <p>
            You may assign your rights under the Agreement to any party that
            consents to, and agrees to be bound by, its terms and conditions;
            Splatboard may assign its rights under the Agreement without
            condition. The Agreement will be binding upon and will inure to the
            benefit of the parties, their successors and permitted assigns.
          </p>
        </Col>
      </Row>
    </section>
  </Layout>
);

export default TermsPage;
